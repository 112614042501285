import { Typography } from 'antd';
import { textJP } from 'common/const';

interface IProps {
  files: any[];
}

const MetadataFiles = ({ files }: IProps) => {
  if (!files?.length) return <Typography.Link type="success">{textJP.none}</Typography.Link>;

  return (
    <>
      {files.map((file: any) => (
        <Typography.Link target="_blank" type="success" href={file.url} key={file?.id} style={{ width: 'fit-content' }}>
          {file?.name}
        </Typography.Link>
      ))}
    </>
  );
};

export default MetadataFiles;
