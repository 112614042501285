import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Image, Modal, Spin, Upload, UploadProps } from 'antd';
import { upload } from 'api/upload';
import { handleErrorMessage } from 'helper';
import { MouseEvent, useRef, useState } from 'react';
import styles from './styles.module.scss';
import icClose from 'assets/icons/x.svg';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import getCroppedImg from 'helper/crop-image';
import { sendPost } from 'api/axios';
import IconEdit from './IconEdit';
import classNames from 'classnames';
interface IUploadProps extends UploadProps {
  value?: string;
  shape?: 'rectangle' | 'circle' | 'square';
  onChange?: (file: any) => void;
  handleUpdateAvatar?: (avatar: string) => void;
  showDelete?: boolean;
  isCrop?: boolean;
  onRemoveImage?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
}

export const UploadFile = ({
  value,
  shape,
  onChange,
  handleUpdateAvatar,
  showDelete = false,
  disabled,
  isCrop,
  onRemoveImage,
  ...rest
}: IUploadProps) => {
  const [image, setImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleUpload = async (file: any) => {
    try {
      setLoading(true);
      const image = await upload(file);
      if (handleUpdateAvatar) {
        handleUpdateAvatar(image?.data[0]);
        setImage(`${image?.domain}/${image?.data[0]}`);
      } else {
        onChange!(`${image?.domain}/${image?.data[0]}`);
        // onChange!(image?.data[0]);
      }
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const mask = (
    <p>
      <EyeOutlined /> プレビュー
    </p>
  );

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [visible, setVisible] = useState(false);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropImage = async () => {
    try {
      setLoading(true);

      // get cropped image
      const croppedImage = await getCroppedImg(image as string, croppedAreaPixels, 0);

      // upload cropped image to s3
      const formData = new FormData();
      formData.append('files', croppedImage as File);
      const imageUrl = await sendPost('/upload', formData);

      // update avatar
      if (handleUpdateAvatar) {
        handleUpdateAvatar(imageUrl?.data[0]);
        setImage(`${imageUrl?.domain}/${imageUrl?.data[0]}`);
      } else {
        onChange!(`${imageUrl?.domain}/${imageUrl?.data[0]}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  const handleOpenCrop = (e: MouseEvent<any>) => {
    if (!isCrop) return;

    // prevent bubble event
    e.stopPropagation();

    // fake click input file
    inputRef.current?.click();
  };

  return (
    <>
      <div
        className={shape === 'rectangle' ? styles.rectangle : shape === 'square' ? styles.square : styles.boxAvatarEdit}
      >
        {/* Image show */}
        {value && (
          <>
            <Image
              src={(handleUpdateAvatar && image) || value}
              alt=""
              className={`${styles.avatar} img-cover`}
              preview={{
                mask: mask,
              }}
            />
            {showDelete && !disabled && (
              <div
                className={classNames(styles.deleteWrap, {
                  [styles.deleteWrapCircle]: shape === 'circle',
                })}
                onClick={(e) => {
                  // sử dụng logic từ bên ngoài nếu có
                  if (onRemoveImage) {
                    onRemoveImage(e);
                    return;
                  }

                  onChange?.('');
                }}
              >
                <img src={icClose} alt="" />
              </div>
            )}
          </>
        )}

        {/* Upload image without crop */}
        {!isCrop && (
          <Upload onChange={handleUpload} beforeUpload={() => false} accept="image/*" disabled={disabled} {...rest}>
            {loading && (
              <div className={styles.spin}>
                <Spin />
              </div>
            )}

            {!disabled && ( // --> with disable hide button edit
              <IconEdit disabled={disabled} isHaveImage={Boolean(value)} />
            )}
            {/* {!disabled && !!value && !showDelete && onRemoveImage && (
              <Button
                onClick={onRemoveImage}
                style={{
                  right: 0,
                  top: 0,
                  position: 'absolute',
                  zIndex: 10,
                }}
                shape="circle"
                icon={<CloseOutlined />}
              />
            )} */}
          </Upload>
        )}

        {/* Upload image with crop */}
        {isCrop && (
          <>
            <Upload
              accept="image/*"
              maxCount={1}
              multiple={false}
              beforeUpload={() => false}
              onChange={({ file }) => {
                const reader = new FileReader();
                reader.onload = () => {
                  setImage(reader.result as any);
                  setVisible(true);
                };
                reader.readAsDataURL(file as any);
              }}
              showUploadList={false}
              disabled={disabled}
              {...rest}
            >
              <div ref={inputRef} />

              {!disabled && ( // --> with disable hide button edit
                <IconEdit disabled={disabled} isHaveImage={Boolean(value)} onClick={handleOpenCrop} />
              )}
            </Upload>
            <Modal
              visible={visible}
              centered
              bodyStyle={{ height: '400px', background: 'white' }}
              onCancel={() => setVisible(false)}
              onOk={() => {
                handleCropImage();
              }}
              confirmLoading={loading}
              okText="保存"
            >
              <div style={{ position: 'relative', height: '100%', marginTop: '25px', width: '100%' }}>
                <Cropper
                  image={image as string}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};
